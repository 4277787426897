import React, { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { closeFileModal, closeHeatmapModal, closeHmsHeatmapModal } from '../../redux/slices/modalSlice';
import HeatmapModal from './HeatmapModal';
import HeatmapHmsModal from './HeatmapHmsModal';
import { FileEnum } from '../../models/types/FileEnum';
import { StyledFileDialog } from '../custom/StyledFileDialog';

const FileViewDialog: FC = ({ children }) => {
  const { isOpen, data } = useAppSelector((state) => state.modal.fileModal);
  const { isOpen: heatmapOpen, data: heatmapData } = useAppSelector((state) => state.modal.heatmapModal);
  const {
    isOpen: heatmapHmsOpen,
    data: heatmapHmsData,
    energyState: heatmapHmsEnergyState,
    inputState: heatmapHmsInputState,
    setInputState: heatmapHmsSetInputState,
  } = useAppSelector((state) => state.modal.heatmapHmsModal);

  const dispatch = useAppDispatch();

  const dialogContent = useMemo(() => {
    if (data?.value && data?.type) {
      if (data.type === FileEnum.IMAGE)
        return <img width={'100%'} src={data.value} height={'100%'} style={{ objectFit: 'contain' }} alt={'icon'} />;
      return <iframe width={'100%'} src={data.value} height={'100%'} />;
    }
  }, [data]);

  const heatMapModal = useMemo(() => {
    if (heatmapOpen && heatmapData)
      return <HeatmapModal open={heatmapOpen} file={heatmapData} handleClose={() => dispatch(closeHeatmapModal())} />;
  }, [heatmapOpen, heatmapData]);

  const heatMapHmsModal = useMemo(() => {
    if (heatmapHmsOpen && heatmapHmsData)
      return (
        <HeatmapHmsModal
          open={heatmapHmsOpen}
          file={heatmapHmsData}
          handleClose={() => dispatch(closeHmsHeatmapModal())}
          energyState={heatmapHmsEnergyState}
          inputState={heatmapHmsInputState}
          setInputState={heatmapHmsSetInputState}
        />
      );
  }, [heatmapHmsOpen, heatmapHmsData]);

  return (
    <>
      <StyledFileDialog open={isOpen} onClose={() => dispatch(closeFileModal())}>
        {dialogContent}
      </StyledFileDialog>
      {heatMapModal}
      {heatMapHmsModal}

      {children}
    </>
  );
};
export default FileViewDialog;

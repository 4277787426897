import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../../axios';
import { openErrorToast, setGlobalLoading } from './appSlice';
import { FileForm } from '../../models/inputTypes/FileType';
import { FileEnum } from '../../models/types/FileEnum';
import { HmsBoundaryConditions, HmsEnergyDissipation } from '../../models/inputTypes/HmsFields';
import {
  hmsBoundaryConditionsInitialState,
  hmsEnergyDissipationInitialState,
} from '../../utils/initialStates/hmsInputState';

type fileModalData = {
  type: FileEnum;
  value: string;
};

type modalSliceType = {
  fileModal: {
    isOpen: boolean;
    data?: fileModalData;
  };
  heatmapModal: {
    isOpen: boolean;
    data?: FileForm | undefined;
  };
  heatmapHmsModal: {
    isOpen: boolean;
    data?: FileForm | undefined;
    energyState: HmsEnergyDissipation;
    inputState: HmsBoundaryConditions;
    setInputState: (value: HmsBoundaryConditions) => void;
  };
};

const initialState: modalSliceType = {
  fileModal: {
    isOpen: false,
    data: undefined,
  },
  heatmapModal: {
    isOpen: false,
    data: undefined,
  },
  heatmapHmsModal: {
    isOpen: false,
    data: undefined,
    energyState: hmsEnergyDissipationInitialState,
    inputState: hmsBoundaryConditionsInitialState,
    setInputState: (hmsBoundaryConditionsInitialState) => {
      hmsBoundaryConditionsInitialState;
    },
  },
};

export const asyncGetFilePreview = createAsyncThunk(
  'modal/filePreview',
  async (params: { project: string; fileName: string }, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading(true));

    try {
      const res: any = await get(`/api/projects/${params.project}/files/${params.fileName}`, {
        responseType: 'blob',
      });
      const type = params.fileName.endsWith('.pdf') ? FileEnum.PDF : FileEnum.IMAGE;
      const fileURL = URL.createObjectURL(
        new Blob([res.data], type === FileEnum.PDF ? { type: 'application/pdf' } : undefined),
      );
      thunkAPI.dispatch(setGlobalLoading(false));
      // window.open(fileURL);
      return { type: type, value: fileURL };
    } catch (e) {
      thunkAPI.dispatch(openErrorToast('Error loading file!'));
      thunkAPI.dispatch(setGlobalLoading(false));

      throw e;
    }
  },
);

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    closeFileModal: (state) => {
      state.fileModal.isOpen = false;
      state.fileModal.data = undefined;
    },

    closeHeatmapModal: (state) => {
      state.heatmapModal.isOpen = false;
      state.heatmapModal.data = undefined;
    },
    openHeatmapModal: (state, action: PayloadAction<FileForm>) => {
      state.heatmapModal.isOpen = true;
      state.heatmapModal.data = action.payload;
    },
    closeHmsHeatmapModal: (state) => {
      state.heatmapHmsModal.isOpen = false;
      state.heatmapHmsModal.data = undefined;
    },
    openHmsHeatmapModal: (
      state,
      action: PayloadAction<{
        data: FileForm;
        energyState: HmsEnergyDissipation;
        inputState: HmsBoundaryConditions;
        setInputState: (value: HmsBoundaryConditions) => void;
      }>,
    ) => {
      state.heatmapHmsModal.isOpen = true;
      state.heatmapHmsModal.data = action.payload.data;
      state.heatmapHmsModal.energyState = action.payload.energyState;
      state.heatmapHmsModal.inputState = action.payload.inputState;
      state.heatmapHmsModal.setInputState = action.payload.setInputState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetFilePreview.fulfilled, (state, action) => {
      state.fileModal.data = action.payload;
      state.fileModal.isOpen = true;
    });
  },
});

export const { openHeatmapModal, closeHeatmapModal, closeFileModal, closeHmsHeatmapModal, openHmsHeatmapModal } =
  modalSlice.actions;
export default modalSlice.reducer;
